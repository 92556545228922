/* footer.css */

.footer-container {
    background-color: rgb(3, 1, 32);
    color: rgb(255, 255, 255);
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    margin-top: 2rem;
}

.footer-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
}

.footer-logo {
    grid-column: 1 / 3;
    text-align: center;
}

.footer-logo-img {
    width: 80px;
    height: auto;
}

.footer-column {
    text-align: center;
}

.footer-heading {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.footer-bottom {
    text-align: center;
    margin-top: 40px;
}

.footer-span {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
}

.footer-h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 14px;
}



@media screen and (max-width: 768px) {
    .footer-content {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 480px) {
    .footer-container {
        padding: 30px 15px;
    }



    .footer-content {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }

    .footer-column {
        margin-bottom: 20px;
        display: block;
    }

    .footer-column .footer-heading {
        margin-bottom: 10px;
    }

    .footer-column .footer-span {
        display: block;
    }

    .footer-h4 {
        margin: 5px 0;
    }

    .footer-bottom {
        text-align: center;
    }

    .footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-column {
        text-align: center;
        margin-bottom: 20px;
    }
}