body {
  min-width: 300px;
}

html {
  font-size: 16px;
  padding: 0;
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: linear-gradient(90deg, #02b6b9, #023d6b);
  --secondary-color: linear-gradient(90deg, #007986, #0a0141);
  --background-color: var(--secondary-color);

  --tituloAYM: #273b49;
  --fondoAYM: #eaf6f8;
  --pAYM: #063251;
  --aAYM: #252e69;
  --fonth1AYM: 2rem;
  --fonth2AYM: 1.5693rem;
  --fonth3AYM: 1.1666rem;







  --TITLE-Azul: #0004ff;
  /*----------------------    var(--TITLE-Azul);    ----*/

  --TITLE-Verde: #40b840;
  /*--- --------------------   var(--TITLE-Verde);    ----*/

  --TEXTO-Azul: #09033a;
  /*--- ------------------   var(--TEXTO-Azul);    ----*/
  --FONDO-Verde: #40b840;
  /*--- ------------------   var(--FONDO-Verde);    ----*/






  --Border-radius: 0.26rem;
  /*--- ------------------   var(--Border-radius);    ----*/
}








/****************ANIMACIONES A&M CONTRATISTAS SAC**************************/

@keyframes DOWNTITLE {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes ONDAS {
  0% {
    transform: scale(1);
  }

  15% {
    opacity: 1;
  }

  100% {
    transform: scale(2.5);
  }
}