.header-container {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr 1fr;
    /* Logo | Espacio flexible | Elementos derechos */
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.nav-links {
    display: flex;
    justify-content: center;
    /* Centra los elementos de navegación */
}

.nav-links a {
    margin: 0 10px;
    /* Espacio entre los elementos de navegación */
    text-decoration: none;
    color: #333333;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #007bff;
}

.help {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* Alinea el icono de ayuda al final del contenedor */
}

.help-icon {
    font-size: 24px;
    margin-right: 5px;
    color: #333333;
    transition: color 0.3s ease;
}

.help-icon:hover {
    color: #007bff;
}



.login-btn:hover {
    background-color: #0056b3;
}

.login-btn {
    background-color: none;
    margin: 0;

}