body {
    background-color: #ffffff;
}



.home-container {
    height: 100%;
    width: 100%;
}

.goldman6g {
    display: grid;
    grid-template-columns: 1fr;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.Goldman__contenedor {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.presentacion__goldman6g {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

}

.presentacion__goldman6g .banner__AYM {
    height: auto;
    width: 100%;
    text-align: center;
    position: relative;
    border-radius: 0.1rem;
    background-blend-mode: hard-light;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 10%;
    background-color: #04012e;
    padding-bottom: 2rem;
}

.presentacion__goldman6g h1,
.presentacion__goldman6g h2 {
    text-align: center;
}

.presentacion__goldman6g .Logo__principal {
    background-color: #ffffff;
    border-radius: 50%;
    padding: 0.5rem;
    user-select: none;
    margin: 0 auto;
    margin-top: -20px;
    z-index: 900;
}

.izquierda__aym {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}



.derecha__aym {
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.izquierda__aym img,
.derecha__aym img {
    margin-right: 10px;
}


.subtitulo__p__aym {
    color: #ffffff;
}

.banner__AYM__SPAN {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
}

.presentacion__goldman6g .caratula__AYM {
    background-color: cadetblue;
    height: 100%;
    width: 100%;
    align-self: center;
    justify-self: center;
    text-align: center;
    margin-top: 0.3rem;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    background: linear-gradient(135deg, #002b55, #005b96);

}

.presentacion__goldman6g div:last-child h1 {
    grid-row: 2;
}











.presentacion__general {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.div__presentacion__general {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.Logo__presentacion__general {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.h2_presentacion__general {
    margin-top: 10px;
    font-size: 24px;
    color: #333333;
    position: relative;
}

.h2_presentacion__general::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 55px;
    height: 3px;
    background-color: #40b840;
}


.h3_presentacion__general {
    margin-top: 10px;
    font-size: 16px;
    color: #333333;
}


.comenzar-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.comenzar-button button {
    padding: 12px 24px;
    background-color: #40b840;
    color: #ffffff;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.comenzar-button button:hover {
    background-color: #00cc00;
}

























.productos__goldman6g {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: #ffffff;
    justify-items: center;
    align-items: center;
}

.titulo__h1__aym {
    font-size: 3.3rem;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;
    max-width: 100%;
    width: 70%;
    margin: 0 auto;
    padding-top: 2rem;
    font-family: 'Alatsi';
    font-weight: 500;
    animation-name: DOWNTITLE;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

.grupo__planes__aym {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 20px;
    padding-top: 2rem;
}

.planes__aym {
    background-color: #ffffff;
    border-radius: 5px;
    display: inline-block;
    padding: 20px;
    cursor: pointer;
    user-select: none;
    justify-content: center;
    align-items: center;
    border: 1px solid #40b840;
}

.planes__aym:hover {
    background-color: #40b840;
    color: #ffffff;
}

.planes__aym h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
}

.planes__aym .lista__aym {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
}

.planes__aym .lista__aym li {
    margin-bottom: 5px;
}

.contenedor__boton__aym {
    display: flex;
    justify-content: center;
    align-items: center;
}

.planes__aym .boton__comprar__aym {
    background-color: #40b840;
    color: #04012e;
    text-decoration: none;
    padding: 10px 30px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
    margin-top: 30px;
    display: inline-block;
    cursor: pointer;
}

.planes__aym:hover .boton__comprar__aym,
.planes__aym .boton__comprar__aym:hover {
    background-color: rgb(1, 1, 105);
    color: #ffffff;
}

.contenedor__precio__aym {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #40b840;
    border-radius: 20px;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 20px;
}

.simbolo__precio__aym {
    font-size: 14px;
    font-weight: bold;
    color: #04012e;
}

.cantidad__precio__aym {
    font-size: 1.6rem;
    font-weight: bold;
    color: #04012e;
}

.lista__aym {
    display: flex;
    flex-direction: column;
}

.lista__aym li {
    display: flex;
    align-items: center;
}

.lista__aym li img {
    margin-right: 10px;
}


















.AYM__CONTRATISTAS__SECTION__CONTACTO {

    display: grid;
    grid-template-columns: 1fr;
    max-width: 90%;
    height: auto;
    align-items: center;
    justify-items: center;
    place-items: center;
    padding: 0;
    margin: 0 auto;

    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 1%;
    margin-top: 2rem;
}


.AYM__CONTRATISTAS__SECTION__CONTACTO__CONTENEDOR {
    grid-column: 1fr;
    background-color: rgba(255, 255, 255, 0.788);
    height: auto;
    width: 90%;
    max-width: 600px;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;
    justify-items: center;
    display: grid;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}



.AYM__CONTRATISTAS__DIV__CONTACTO__CONTENEDOR {
    display: grid;
    place-items: center;
    width: 100%;

}





form {
    display: grid;
    grid-template-columns: 1fr;
    font-family: 'Alatsi';
    margin-bottom: 2rem;
    width: 100%;
    gap: 1rem;
    justify-items: center;
    align-items: center;
    background-color: transparent;
    border-radius: 0.5rem;
}

.formularioh2 {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    font-size: 3rem;
    text-align: center;
    color: #252e69;

}


.form-label {
    align-self: center;
    justify-self: left;
    font-size: 1rem;
    margin-left: 30px;

}

.caracteresp {
    align-self: center;
    justify-self: left;
    font-family: 'Open Sans', sans-serif;
    font-weight: lighter;
    color: var(--TEXTO-Azul);
    font-size: 0.9rem;
}


input,
textarea {
    width: 85%;
    margin: 0 auto;
    padding: 0.6rem;
    margin-bottom: 15px;
    margin-top: 0.3rem;
    border: 1px solid rgb(3, 3, 60);
    border-radius: 5px;
    box-shadow: 0 0 2px #2200ff;
    font-size: 1rem;
    padding-left: 1%;
    height: 20px;

}

input[type="submit"] {
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 15px;

}

input[type="submit"]:hover {
    background-color: #45a049;
}

input:focus,
textarea:focus {
    outline: none;
    box-shadow: 0 0 5px #40b840;
}

textarea.mensaje-textarea {
    height: 150px;
    resize: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    font-size: 0.8rem;
}

.caracteresp {
    margin-left: 10%;
    margin-bottom: 2rem;
}

.submitb {
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    width: 50%;
    border: none;
    border-radius: var(--Border-radius);
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    align-self: center;
    justify-self: center;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    margin-bottom: 2rem;
}

.submitb:hover {
    background-color: #100698;
    color: #ffffff;


}

.telefono-input.error {
    border: 2px solid red;
}












.AYM__CONTRATISTAS__DIV__slide {

    grid-column: 2;
    max-width: 100%;
    align-items: flex-end;
    display: block;
    justify-content: center;
    align-items: center;


    padding: 0;
    margin: 0;

}

.contenedor__slider {
    grid-column: 1fr;

    height: 80vh;
    width: 100%;
    max-width: 100%;
    border-radius: 0.6rem;
    margin: 0 auto;
    align-items: center;
    align-self: center;
    margin-top: 1.5rem;
}






.slider {
    display: grid;
    grid-column: 1 / -1;
    width: 100%;
    height: 70vh;
    padding: 0;
    margin: 0;
}





.slide {
    display: none;
    align-items: center;
    justify-content: center;
}

.fade {
    opacity: 1;
    animation: fade 0.666s linear;
}


.slide.active {
    display: grid;

}




.slide-content {
    text-align: center;
    width: 100%;
}

.slide-content h2 {
    font-family: 'Alatsi';
    font-style: normal;
    line-height: 1;
    margin: 0 auto;
    font-size: 3rem;
    color: white;
    width: 80%;
    text-shadow: 2px 2px 2px rgba(66, 66, 66, 0.666);
}

.slide-content p {

    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.1;
    width: 80%;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
    padding: 1rem 0;
    color: #ffffff;
    text-shadow: 2px 2px 2px rgba(66, 66, 66, 0.666);
}


.slide-content a {
    font-size: 1rem;
    font-family: 'Georgia, serif';
}


.btn {
    background-color: var(--FONDO-Verde);
    color: var(--TEXTO-Azul);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    text-transform: none;
    transition: 0.666s;
    border-radius: var(--Border-radius);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

}

.btn:hover {
    background-color: rgb(29, 1, 130);
    color: white;
    transform: scale(1.03, 1.03);
}



.slide1 {
    background-image: url('../slider1/AYM__01__kingston_new_homes.webp');

}

.slide2 {
    background-image: url('../slider1/AYM__02__escuela_francesa.webp');

}




.slide3 {
    background-image: url('../slider1/AYM__03__andfood.webp');
}

.slide4 {
    background-image: url('../slider1/AYM__04__ganansol.webp');
}

.slide5 {
    background-image: url('../slider1/AYM__05__alizeas.webp');
}

.slider>.slide {
    background-blend-mode: soft-light;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 30%;
}


.slider__arrow__contenedor {
    position: relative;
}


.slider-arrow {
    position: absolute;
    top: -10vh;
    width: 35px;
    height: 35px;
    display: flex;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transform: translateY(-50%);


}



.slider-arrow img {
    width: 66px;
    filter: brightness(0) saturate(100%) invert(18%) sepia(86%) saturate(5836%) hue-rotate(225deg) brightness(93%) contrast(95%);
    transition: transform 0.2s ease-in-out;
}


.slider-arrow img:hover {
    transform: scale(1.2);
    filter: brightness(0) saturate(600%) invert(11%) sepia(70%) saturate(5229%) hue-rotate(120deg) brightness(210%) contrast(92%);

}


.slider-arrow-left {
    left: 40px;
}

.slider-arrow-right {
    right: 40px;

}












@media only screen and (max-width: 1000px) {
    .presentacion__goldman6g .banner__AYM {
        height: 20px;
        min-height: 50px;
    }

    .presentacion__goldman6g .caratula__AYM {
        height: 90vh;
        min-height: 500px;
    }

    .grupo__planes__aym {
        grid-template-columns: 1fr;
    }

    .planes__aym {
        margin-right: 0;
    }


    .contenedor__slider,
    .slider {
        height: 50vh;
    }





    .submitb {
        font-size: 1rem;
    }

    textarea.mensaje-textarea {
        font-size: 1rem;
    }




}

@media only screen and (max-width: 550px) {



    .banner__AYM {
        height: 10px;
    }

    .banner__AYM__SPAN {
        font-size: 0.8rem;
    }

    .Logo__principal {
        padding: 1rem;
    }

    .titulo__h1__aym {
        font-size: 1.5rem;
    }

    .planes__aym h3 {
        font-size: 20px;
    }

    .contenedor__precio__aym {
        border-radius: 10px;
    }

    .simbolo__precio__aym {
        font-size: 12px;
    }

    .cantidad__precio__aym {
        font-size: 1.2rem;
    }



    .presentacion__goldman6g .Logo__principal {
        height: 60%;
        width: auto;
    }












    .AYM__CONTRATISTAS__DIV__slide {

        grid-column: 2;
        max-width: 100%;
        align-items: flex-end;
        display: block;
        justify-content: center;
        align-items: center;


        padding: 0;
        margin: 0;

    }

    .contenedor__slider {
        grid-column: 1fr;
        height: 60vh;
        width: 100%;
        max-width: 100%;
        border-radius: 0rem;
    }

    .slider {
        width: 100%;
        height: 60vh;
        margin: 0 auto;
    }



    .slide {
        display: none;
        align-items: center;
        justify-content: center;
    }

    .fade {
        opacity: 1;
        animation: fade 0.666s linear;
    }


    .slide.active {
        display: grid;

    }




    .slide-content {
        text-align: center;
        width: 100%;
    }

    .slide-content h2 {

        font-size: 24px;
        color: white;
        width: 80%;

    }

    .slide-content p {

        font-size: 18px;
        line-height: 1.1;
        width: 80%;
        margin: 0 auto;
        font-weight: 600;
        text-align: center;
        padding: 1rem 0;
        color: #ffffff;
        text-shadow: 2px 2px 2px rgba(66, 66, 66, 0.666);
    }


    .slide-content a {
        font-size: 14px;
        font-family: 'Georgia, serif';
    }


    .btn {
        background-color: var(--FONDO-Verde);
        color: var(--TEXTO-Azul);
        border: none;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin-top: 10px;
        text-transform: none;
        transition: 0.666s;
        border-radius: var(--Border-radius);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

    }

    .btn:hover {
        color: white;
        transform: scale(1.03, 1.03);
    }

    .slider>.slide {
        background-blend-mode: soft-light;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 30%;
    }


    .slider__arrow__contenedor {
        position: relative;
    }


    .slider-arrow {
        position: absolute;
        top: -10vh;
        width: 6%;
        height: 6%;
        display: flex;
        background-color: transparent;
        border: none;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translateY(-50%);


    }



    .formularioh2 {
        font-size: 1.6rem;
    }

    .banner__AYM__SPAN {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .banner__AYM__SPAN .izquierda__aym,
    .banner__AYM__SPAN .derecha__aym {
        margin-bottom: 0;


    }

    .izquierda__aym img,
    .derecha__aym img {
        margin-right: 10px;
    }

    .izquierda__aym,
    .derecha__aym {
        margin-left: 6px;
    }




    .goldman6g {
        display: grid;
        grid-template-columns: 1fr;
        height: 60vh;
        align-items: center;
        justify-content: center;
    }

    .presentacion__goldman6g .caratula__AYM {
        background-color: cadetblue;
        height: 30%;
        width: 100%;
        align-self: center;
        justify-self: center;
        text-align: center;
        margin-top: 0.3rem;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        background: linear-gradient(135deg, #002b55, #005b96);

    }




}